import { gql, useQuery, useSubscription } from '@apollo/client';
import { Client, MutationUpdate, SUBSCRIPTION_UPDATES } from 'navabilitysdk';
import React from 'react';
import { INITIAL_STATE } from '../../global/state/schema';

const FILTERS_QUERY = gql`
    query GetFilters {
        filters @client {
            twin {
                userId
                robotId
                sessionId
                variableLabel
                requestId
            }
            swarm {
                environmentIds
                userIds
                robotIds
                sessionIds
            }
        }
    }`;
    
const SubscriptionUpdates = () => {
    const filterQuery = useQuery(FILTERS_QUERY)
    const filters = filterQuery?.data?.filters || INITIAL_STATE.filters
    const client = Client(filters.twin.userId, filters.twin.robotId, filters.twin.sessionId);

    // const { enqueueSnackbar } = useSnackbar();
    const appendEvent = (event:any) => {
        if(event?.subscriptionData?.data?.mutationUpdate) {
            const mut = event.subscriptionData.data.mutationUpdate
            const message = `Server: ${mut.state} ${mut.action} for request '${mut.requestId && mut.requestId.substring(0,8)}'`;
            // enqueueSnackbar(message, {variant: 'success'});
        }
    }

    useSubscription<MutationUpdate>(
        gql(SUBSCRIPTION_UPDATES),
        { variables: {client},
        onSubscriptionData:appendEvent}
      );

    return <div></div>
}

export default SubscriptionUpdates