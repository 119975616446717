import React from "react"
import SEO from "../../components/common/seo"
import GeometricMapView from '../../components/geometric-map/geometric-map-view'
import SubscriptionUpdates from "../../components/activity/subscription-updates"
import Paywall from "../../components/paywall/paywall"
import UrlSearch from "../../components/url/url-search"

const MapPage = ({location}:any) => {
  return (
    <>
      <SEO title="NavAbility Geometric Map" />
      <UrlSearch location={location}/>
      {/* <Paywall> */}
        <GeometricMapView />
        <SubscriptionUpdates />
      {/* </Paywall> */}
    </>
  )
}

export default MapPage;
