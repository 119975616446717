import { Engineering } from '@mui/icons-material';
import { Alert } from '@mui/material';
import React from 'react';

export default function UnderConstructionAlert() {
  return (
    <Alert severity="warning" icon={<Engineering />}>
      This page is currently being upgraded to the new V4 API and may not function as expected. We'll announce
      improvement in the news as we update these components.
    </Alert>
  );
}
