import React, { useEffect } from 'react'
import { gql, useApolloClient } from "@apollo/client"
import { navigate } from 'gatsby';

const FILTERS_QUERY = gql`
query GetFilters {
  filters @client {
    twin {
      userId
      robotId
      sessionId
      variableLabel
      requestId
    }
    swarm {
      environmentIds
      userIds
      robotIds
      sessionIds
    }
  }
}`;

export const PAGE_QUERY = gql`
query GetPages {
  pages @client {
    getStarted {
      introduction {
        step
        context
        firstRobotId
        firstSessionId
        secondRobotId
        secondSessionId
      }
    }
    geometricMap {
      showDyads
      showDistribution
      showClown
    }
  }
}`;

const UrlSearch = ({ location }: any) => {
  const globalState = useApolloClient()
  useEffect(() => {
    var url = new URL(location.href);
    const userId = url.searchParams.has('userId') ? (url.searchParams.get('userId') || '') : null;
    const robotStartsWith = url.searchParams.has('robotStartsWith') ? (url.searchParams.get('robotStartsWith') || '') : null;
    const sessionStartsWith = url.searchParams.has('sessionStartsWith') ? (url.searchParams.get('sessionStartsWith') || '') : null;
    const variableStartsWith = url.searchParams.has('variableStartsWith') ? (url.searchParams.get('variableStartsWith') || '') : null;
    const showDistribution = url.searchParams.has('showDistribution') ? (url.searchParams.get('showDistribution') || 'true') : null;
    const showClown = url.searchParams.has('showClown') ? (url.searchParams.get('showClown') || 'true') : null;
    const showDyads = url.searchParams.has('showDyads') ? (url.searchParams.get('showDyads') || 'true') : null;
    const doRedirect = userId !== null || robotStartsWith !== null || sessionStartsWith !== null || variableStartsWith !== null || showDistribution !== null || showClown !== null || showDyads !== null
    if (userId !== null || robotStartsWith !== null || sessionStartsWith !== null || variableStartsWith !== null) {
      globalState.query({ query: FILTERS_QUERY }).then(result => {
        let filters = result.data.filters
        const doRedirect = userId !== null || robotStartsWith !== null || sessionStartsWith !== null || variableStartsWith 
        if (userId !== null) {
          filters = { ...filters, twin: { ...filters.twin, userId } }
        }
        if (robotStartsWith !== null) {
          filters = { ...filters, twin: { ...filters.twin, robotId: robotStartsWith } }
        }
        if (sessionStartsWith !== null) {
          filters = { ...filters, twin: { ...filters.twin, sessionId: sessionStartsWith } }
        }
        if (variableStartsWith !== null) {
          filters = { ...filters, twin: { ...filters.twin, variableLabel: variableStartsWith } }
        }
        globalState.writeQuery({
          query: FILTERS_QUERY,
          data: {
            filters: filters
          }
        })
      })
    }
    if (showDistribution !== null || showClown !== null || showDyads !== null) {
      globalState.query({ query: PAGE_QUERY }).then(result => {
        let pages = result.data.pages
        if (showDistribution !== null) {
          const parsedShowDistribution = showDistribution.toUpperCase() === 'TRUE'
          pages = { ...pages, geometricMap: { ...pages.geometricMap, showDistribution: parsedShowDistribution } }
        }
        if (showClown !== null) {
          const parsedShowClown = showClown.toUpperCase() === 'TRUE'
          pages = { ...pages, geometricMap: { ...pages.geometricMap, showClown: parsedShowClown } }
        }
        if (showDyads !== null) {
          const parsedShowDyads = showDyads.toUpperCase() === 'TRUE'
          pages = { ...pages, geometricMap: { ...pages.geometricMap, showDyads: parsedShowDyads } }
        }
        globalState.writeQuery({
          query: PAGE_QUERY,
          data: {
            pages: pages
          }
        })
      })
    }
    if (doRedirect) {
      navigate(url.pathname)
    }
  })
  return <div></div>
}

export default UrlSearch