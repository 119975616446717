import { gql, useApolloClient, useQuery } from '@apollo/client';
import { Button, CardActions } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Client, solveSession } from 'navabilitysdk';
import React, { useState } from 'react';
import { INITIAL_STATE } from '../../global/state/schema';
import UnderConstructionAlert from '../common/alerts/UnderConstructionAlert';
import { DarkNegativeSendIcon } from '../icons/icons';
import GeometricMap2d from './geometric-map-2d';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centered: {
        margin: 'auto'
    },
    card: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      textAlign: 'left',
      color: theme.palette.text.primary
    },
    textField: {
      width: "100%",
      margin: theme.spacing(1)
    }    
  }),
);

const FILTERS_QUERY = gql`
query GetFilters {
    filters @client {
        twin {
            userId
            robotId
            sessionId
            variableLabel
            requestId
        }
        swarm {
            environmentIds
            userIds
            robotIds
            sessionIds
        }
    }
}`;

const PAGE_QUERY = gql`
query GetPageSettings {
    pages @client {
        geometricMap {
            showDistribution
            showClown
            showDyads
        }
    }
}`;

const GeometricMapView = () => {
    const classes = useStyles()
    const globalState = useApolloClient()
    const filterQuery = useQuery(FILTERS_QUERY)
    const filters = filterQuery?.data?.filters || INITIAL_STATE.filters

    const [showPpes, setShowPpes] = useState(true)
    const [showDyads, setShowDyads] = useState(false)
    const [showEllipse, setShowEllipse] = useState(false)
    const [showCloud, setShowCloud] = useState(false)
    const [showDistribution, setShowDistribution] = useState(false)
    const [showClown, setShowClown] = useState(false)

    const updatePageSettingsFromCache = (data:any) => {
        const pageSettings = data?.pages?.geometricMap || INITIAL_STATE.pages.geometricMap
        setShowDistribution(pageSettings.showDistribution)
        setShowClown(pageSettings.showClown)
        setShowDyads(pageSettings.showDyads)
        setShowPpes(!pageSettings.showDyads)
    }
    useQuery(PAGE_QUERY, { onCompleted:updatePageSettingsFromCache } )

    const handleSolve = () => {
        const client = Client(filters.twin.userId, filters.twin.robotId, filters.twin.sessionId)
        solveSession(globalState, client)
    }

    const handlePpeClick = () => {
        if(!showPpes && !showDyads) {
            setShowPpes(true)
            setShowDyads(false)
        } else if (showPpes && !showDyads) {
            setShowPpes(false)
            setShowDyads(true)
        } else if (!showPpes && showDyads) {
            setShowPpes(true)
            setShowDyads(true)
        } else {
            setShowPpes(false)
            setShowDyads(false)
        }
    }

    const getPpeText = () => {
        if(!showPpes && !showDyads) {
            return "Show Ppes"
        } else if (showPpes && !showDyads) {
            return "Show Dyads"
        } else if (!showPpes && showDyads) {
            return "Show Both"
        } else {
            return "Hide Ppes"
        }
    }
    
    const handleBeliefClick = () => {
        if(!showEllipse && !showCloud) {
            setShowEllipse(true)
            setShowCloud(false)
        } else if (showEllipse && !showCloud) {
            setShowEllipse(false)
            setShowCloud(true)
        } else if (!showEllipse && showCloud) {
            setShowEllipse(true)
            setShowCloud(true)
        } else {
            setShowEllipse(false)
            setShowCloud(false)
        }
    }

    const getBeliefText = () => {
        if(!showEllipse && !showCloud) {
            return "Show Belief"
        } else if (showEllipse && !showCloud) {
            return "Show Particles"
        } else if (!showEllipse && showCloud) {
            return "Show Both"
        } else {
            return "Hide Belief"
        }
    }

    return (
        <Card className={classes.card}>
            <UnderConstructionAlert/>
            <CardHeader 
                title="Geometric Map"
                subheader="Geometric representation of the variables in the factor graph, in space and time, inferred by the solving engine."/>
            <CardContent>
                <Card variant="outlined" className={classes.card}>
                    <CardContent>
                        <GeometricMap2d userId={filters.twin.userId} robotFilter={filters.twin.robotId} sessionFilter={filters.twin.sessionId} variableFilter={filters.twin.variableLabel} legend={true} ellipses={showEllipse} clouds={showCloud} ppes={showPpes} dyads={showDyads} distributions={showDistribution} clown={showClown}></GeometricMap2d>
                    </CardContent>
                    <CardActions>
                        <Button className={classes.centered} variant="contained" color="secondary"
                            startIcon={<DarkNegativeSendIcon fontSize="large"/>} 
                            onClick={handlePpeClick}>
                            {getPpeText()}
                        </Button>
                        <Button className={classes.centered} variant="contained" color="secondary"
                            startIcon={<DarkNegativeSendIcon fontSize="large"/>} 
                            onClick={handleBeliefClick}>
                            {getBeliefText()}
                        </Button>
                        <Button className={classes.centered} variant="contained" color="secondary"
                            startIcon={<DarkNegativeSendIcon fontSize="large"/>} 
                            onClick={() => setShowDistribution(value => !value)}>
                            {showDistribution ? "Hide Distributions" : "Show Distributions"}
                        </Button>
                        <Button className={classes.centered} variant="contained" color="secondary"
                            startIcon={<DarkNegativeSendIcon fontSize="large"/>} 
                            onClick={() => setShowClown(value => !value)}>
                            {showClown ? "Hide Clown Colors" : "Show Clown Colors"}
                        </Button>
                        <Button className={classes.centered} variant="contained" color="secondary"
                            startIcon={<DarkNegativeSendIcon fontSize="large"/>} 
                            onClick={handleSolve}>
                            Solve
                        </Button>
                    </CardActions>
                </Card>
            </CardContent>
        </Card>
    )
}

export default GeometricMapView